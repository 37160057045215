import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { set } from "lodash";

export interface CreateUserType {
    id: number;
    name: string;
    companyName: string;
    password: string;
    email: string;
    celPhone: string;
    username: string;
    status: number;
    type: number;
    expirationDate: string;
    typeCandidate: number;
}

export default function useUser() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [listUsers, setListUsers] = useState<CreateUserType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListUsers, setInitialListUsers] = useState<CreateUserType[]>([]);

    async function getAllUsers() {
        setLoading(true);
        try {
            const response = await api.get(`/Users/getallusers`);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar usuários:", error);
            return null;
        }
    }

    // async function getAllUsersWithTypeCandidate(){
    //     setLoading(true);
    //     try {
    //         const response = await api.get(`/Users/getAllUsersWithTypeCandidate`);
    //         setLoading(false);
    //         return response.data;
    //     } catch (error) {
    //         setLoading(false);
    //         console.error("Erro ao buscar usuários:", error);
    //         return null;
    //     }
    // }

    async function getAllUsersWithTypeCandidate(
        pageNumber: number,
        name?: string,
    ) {
        setLoading(true);

        let url = `/Users/getAllUsersWithTypeCandidate?pageNumber=${pageNumber}&pageSize=10`;

        if (name) {
            url = url + `&name=${name}`;
        }

        try {
            const response = await api.get(url);
            setListUsers(response.data.result);
            setInitialListUsers(response.data.result);
            setPageCount(response.data.pageCount);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar usuários:", error);
            return null;
        }
    }

    async function createUsersFromCsv(file: File): Promise<void> {
        setLoading(true);
        const formData = new FormData();
        
        formData.append('file', file);

        try {
            const response = await api.post(`/Candidate/create-from-csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setLoading(false);
            showToast("sucess", "Candidatos criados com sucesso!");
        } catch (error) {
            setLoading(false);
            console.error("Erro ao criar candidatos a partir do CSV:", error);
            showToast("error", "Erro ao criar candidatos a partir do CSV.");
        }
    }

    async function createUser(userData: CreateUserType): Promise<CreateUserType | null> {
        setLoading(true);
        console.log(userData);
        if (userData.type == 3) {
            userData.type = 1;
        }
        try {
            let response;
            if (userData.type == 1) {
                response = await api.post(`/Candidate/create-with-expiration?expirationDate=${userData.expirationDate}`, userData);
            } else if (userData.type == 2) {
                response = await api.post(`/Company/create-with-expiration?expirationDate=${userData.expirationDate}`, userData);
            } else {
                setLoading(false);
                console.error("Tipo de usuário não reconhecido");
                return null;
            }
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao criar usuário:", error);
            return null;
        }
    }

    async function updateUserWithType(userData: CreateUserType): Promise<CreateUserType | null> {
        setLoading(true);
        try {
            console.log("userData", userData);
            let response;
            if (userData.type == 1 || userData.type == 0) {
                response = await api.put(`/Candidate/updateUserByUserId/${userData.id}`, userData);
            } else if (userData.type == 2) {
                response = await api.put(`/Company/updateUserByUserId/${userData.id}`, userData);
            } else {
                setLoading(false);
                console.error("Tipo de usuário não reconhecido");
                return null;
            }
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao atualizar usuário:", error);
            return null;
        }
    }

    async function updateUser(userId: number, userData: CreateUserType): Promise<CreateUserType | null> {
        setLoading(true);
        try {
            const response = await api.put(`/Users/${userId}`, userData);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao atualizar usuário:", error);
            return null;
        }
    }

    return { 
        createUsersFromCsv,
        createUser, 
        getAllUsers, 
        getAllUsersWithTypeCandidate, 
        updateUser, 
        updateUserWithType,
        listUsers,
        pageCount,
        initialListUsers
    };
}